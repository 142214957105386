import React, { useState } from "react";

export const NMSCalculator = () => {
  const [value, setValue] = useState(0);

  return (
    <div className="container py-5 subSection">
      <h2 className="display-4 text-center mb-5">NMS Calculator</h2>
      <div className="row g-4 justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="card h-100 shadow-lg border-0">
            <div className="card-body p-4 p-md-5">
              <h3 className="card-title h4 mb-4">Calculate NMS</h3>
              <div className="mb-4">
                <label htmlFor="itemCount" className="form-label text-muted">
                  Enter Number of Items
                </label>
                <input
                  id="itemCount"
                  className="form-control form-control-lg bg-light rounded-5"
                  value={value}
                  onChange={(e) => setValue(Number(e.target.value))}
                  type="number"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="card h-100 shadow-lg border-0 primaryBackground text-white">
            <div className="card-body p-4 p-md-5">
              <h3 className="card-title h4 mb-4">Summary</h3>
              <div className="mb-4">
                <h6 className="text-white-50 mb-2">Total Revenue Per Month</h6>
                <p className="display-5 fw-bold mb-0">
                  £{(Math.round(value / 1000) * 1000 * 0.28).toFixed(0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
