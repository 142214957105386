import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import testamonyDerix from "../assets/testamony-derix.png";
import testamonyImperial from "../assets/testamony-imperial.png";
import testamonyMaplestead from "../assets/testamony-maplestead.png";
import testamonyMedway from "../assets/testamony-medway.png";
import testamonyNewington from "../assets/testamony-newington.png";

export const Testamonies = () => {
  // Array of testimonies, each containing text and a logo
  const testimonies = [
    {
      text: `From doing 5-6 NMS's a month to over 100 a month what can I say .. Pharma Service provide a very easy seamless service from data extraction to end of month report. Regular updates if we need to highlight anything with GP … best move I have made this year! ​`,
      logo: testamonyImperial,
    },
    {
      text: `Great NMS service from Pharma Services. They get all the data and their team of pharmacists carry out the NMS on your behalf. This frees up your time to concentrate on other services such as pharmacy first and vaccinations.​`,
      logo: testamonyMaplestead,
    },
    {
      text: `This service has taken the some of the pressures of our daily chores and generated some income which we would have never gained.​`,
      logo: testamonyMedway,
    },
    {
      text: `The service from Pharma Services is next to none. The feedback from patients is of a very high standard and the communication back to the pharmacy is excellent regarding any action regarding interventions. My time has been greatly increased to enable me to push other services that otherwise I would not have time to do. ​`,
      logo: testamonyNewington,
    },
    {
      text: `Lovely and helpful team that hit their target seamlessly on monthly basis !​`,
      logo: testamonyDerix,
    },
  ];

  return (
    <div className="container my-5 subSection">
      <h2 className="mb-4 text-center">What Our Clients Say</h2>

      {/* Horizontal scroll container */}
      <div className="d-flex flex-nowrap overflow-auto">
        {testimonies.map((testimony, index) => (
          <div className="card mx-3" style={{ minWidth: "300px" }} key={index}>
            <div className="card-body">
              <div className="text-center mb-3">
                {/* Company Logo */}
                <img
                  src={testimony.logo}
                  alt={`Company ${index + 1} Logo`}
                  className="img-fluid cardImg"
                  //   style={{ maxHeight: "80px" }}
                />
              </div>
              {/* Testimony Text */}
              <p className="card-text">{testimony.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
