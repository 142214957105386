// git add .
// git commit -m "launch update"
// git push origin main

// For Dev:
// cd front-end
// npm start
// For Prod:
// cd main ie cd .. form front-end
// NODE_ENV=production firebase deploy

import "./App.css";
import { MyNavbar } from "./components/navbar";
import { Hero } from "./sections/hero";
import { About } from "./sections/about";
import { NMSCalculator } from "./sections/NMSCalculator";
import { FAQs } from "./sections/FAQs";
import { Footer } from "./components/footer";
import { ContactUs } from "./sections/contact";
import { ComingSoon } from "./sections/comingSoon";
import { Testamonies } from "./sections/testamonies";

function App() {
  return (
    <div className="App">
      <MyNavbar />

      <section id="home">
        <Hero />
      </section>

      <section id="aboutUs">
        <About />
      </section>
      <section id="nmsCalculator">
        <NMSCalculator />
      </section>

      <section id="faqs">
        <FAQs />
      </section>
      <section id="testamonies">
        <Testamonies />
      </section>

      <section id="comingSoon">
        <ComingSoon />
      </section>

      <section id="contactUs">
        <ContactUs />
      </section>

      <Footer />
    </div>
  );
}

export default App;
