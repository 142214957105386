// import React from "react";

// export const Hero = () => {
//   const handleScroll = (id) => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: "smooth" });
//     }
//   };
//   return (
//     <div className="hero d-flex justify-content-center align-items-center p-5">
//       <div className="text-center d-flex flex-column align-items-center">
//         <h2 className="fw-bold w-50 m-5">
//           Maximise Your Pharmacy's Potential with Our Remote NMS Service
//         </h2>
//         <h4 className="w-75 m-2">
//           Improve care, save time, boost earnings, and grow your pharmacy’s
//           reputation with our remote NMS service.
//         </h4>
//         <div className="mt-4">
//           <button
//             className="border-0 bg-transparent text-white"
//             onClick={() => handleScroll("contactUs")}
//           >
//             Get Started Today
//             <svg
//               style={{ marginLeft: "0.5rem", width: "0.8rem", fill: "white" }}
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 448 512"
//             >
//               <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
//             </svg>
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

import React from "react";

export const Hero = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const heroStyle = {
    position: "relative",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
    overflow: "hidden",
  };

  const overlayStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  };

  return (
    <div className="hero heroStyle" style={heroStyle}>
      <div style={overlayStyle}></div>
      <div
        className="container h-100 d-flex justify-content-center align-items-center position-relative"
        style={{ zIndex: 2 }}
      >
        <div className="text-center">
          <h2 className="fw-bold mb-4 display-4">
            Maximise Your Pharmacy's Potential with Our Remote NMS Service
          </h2>
          <h4 className="mb-5 px-3 px-md-5">
            Improve care, save time, boost earnings, and grow your pharmacy's
            reputation with our remote NMS service.
          </h4>
          <button
            className="btn btn-outline-light btn-lg"
            onClick={() => handleScroll("contactUs")}
          >
            Get Started Today
            <svg
              className="ms-2"
              style={{ width: "1rem", fill: "currentColor" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
