import React from "react";
import logo from "../assets/pharmaServicesLogo.png";

export const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Column: Logo */}
          <div className="col-md-6 text-start">
            <img src={logo} alt="Logo" className="img-fluid w-50" />
          </div>

          {/* Right Column: Text */}
          <div className="col-md-6 text-end">
            <p className="mb-0">
              &copy; 2023. All Rights Reserved By Pharma Service Ltd.
            </p>
            <a
              href="https://ico.org.uk/ESDWebPages/RegistrationCertificate/ZB696031"
              className="text-white text-decoration-none "
            >
              ICO Certificate
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
