import React, { useState } from "react";

export const ContactForm = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    prescriptionItems: "",
    pmrSystem: "",
    message: "",
  });

  // name, email, phone, location, datetime, message

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/submit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          prescriptionItems: "",
          pmrSystem: "",
          message: "",
        });
        setIsSubmitted(true); // Trigger animation
        setTimeout(() => setIsSubmitted(false), 1000);
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };
  return (
    <div className="subSection container card rounded-3">
      <h2 className="w-100 text-center">ContactForm</h2>
      <form onSubmit={handleSubmit} className="p-4">
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            onChange={handleChange}
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            onChange={handleChange}
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            onChange={handleChange}
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number
          </label>
          <input
            onChange={handleChange}
            type="number"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="prescriptionItems" className="form-label">
            Prescription Items (please provide a combined estimate for multiple
            stores)
          </label>
          <textarea
            onChange={handleChange}
            className="form-control"
            id="prescriptionItems"
            name="prescriptionItems"
            value={formData.prescriptionItems}
            rows="3"
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="pmrSystem" className="form-label">
            PMR System
          </label>
          <textarea
            onChange={handleChange}
            className="form-control"
            id="pmrSystem"
            name="pmrSystem"
            value={formData.pmrSystem}
            rows="3"
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            onChange={handleChange}
            className="form-control"
            id="message"
            name="message"
            value={formData.message}
            rows="5"
          ></textarea>
        </div>

        <button
          type="submit"
          className={`btn primaryBackground text-white ${
            isSubmitted ? "submitted" : "notSubmitted"
          }`}
        >
          {isSubmitted ? "message sent" : "Submit"}
        </button>
      </form>
    </div>
  );
};
