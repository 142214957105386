import React from "react";

export const FAQs = () => {
  return (
    <div className="subSection container ">
      <h2 className="w-100 text-center">FAQs</h2>
      <div className="accordion" id="accordionExample">
        {/* 1 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Why should I pay for something I can do in-house?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Many pharmacies find managing NMS in-house less effective than
              expected. Our service ensures consistent NMS claims, saves time,
              maximises revenue, and delivers results without disrupting your
              operations.
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Can you offer a cheaper price?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Our pricing is designed for value and sustainability. Besides NHS
              payments, you’ll save time and resources, enabling you to focus on
              other tasks and improve efficiency, offering a strong return from
              our service.
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              How can I trust you with my patients and their data?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Our pharmacists are highly trained and adhere to strict GDPR
              guidelines, ensuring data protection. We can also provide
              references from pharmacies that have experience with our service.
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              What if you don't meet the target number of NMS claims?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              We work closely with you to meet targets and adjust the service if
              needed, ensuring we maximise your revenue.
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              How do I get started?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Simply review and sign the contract and SLA. Our team will guide
              you through the quick and seamless onboarding process, typically
              completed in under 10 minutes.
            </div>
          </div>
        </div>

        {/* 6 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Is there a long-term contract?
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              No, we offer rolling contracts with no long-term commitment,
              allowing you to try our service with no obligation.
            </div>
          </div>
        </div>

        {/* 7 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              How long until I see results?
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              All pharmacies see immediate results within the first month of
              implementation.
            </div>
          </div>
        </div>

        {/* 8 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              What’s included in the service?
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              We manage every aspect of NMS, from patient engagement to doctor
              referrals and record documentation, allowing you to focus on other
              tasks.
            </div>
          </div>
        </div>

        {/* 9 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              Can I cancel the service if it’s not working?
            </button>
          </h2>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Yes, you can cancel at any time with no penalty, as we operate on
              a rolling contract.
            </div>
          </div>
        </div>

        {/* 10 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              Can I speak to someone at my convenience?
            </button>
          </h2>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              We’re available Monday to Saturday until 9 PM. You can call us or
              leave your details below and a member of the team will contact
              you.
            </div>
          </div>
        </div>

        {/* 11 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              What is your price?
            </button>
          </h2>
          <div
            id="collapseEleven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Contact us to discuss pricing based on your specific needs.
              Depending on your pharmacy or PMR system, we may be able to offer
              a discount.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// import React from "react";

// export const FAQs = () => {
//   return (
//     <div className="subSection container">
//       <h2 className="w-100 text-center">FAQs</h2>
//       <div className="accordion" id="accordionExample">
//         <div className="row">
//           <div className="col-md-6">
//             {/* 1 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseOne"
//                   aria-expanded="false"
//                   aria-controls="collapseOne"
//                 >
//                   Why should I pay for something I can do in-house?
//                 </button>
//               </h2>
//               <div
//                 id="collapseOne"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   Many pharmacies find managing NMS in-house less effective than
//                   expected. Our service ensures consistent NMS claims, saves
//                   time, maximises revenue, and delivers results without
//                   disrupting your operations.
//                 </div>
//               </div>
//             </div>

//             {/* 2 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseTwo"
//                   aria-expanded="false"
//                   aria-controls="collapseTwo"
//                 >
//                   Can you offer a cheaper price?
//                 </button>
//               </h2>
//               <div
//                 id="collapseTwo"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   Our pricing is designed for value and sustainability. Besides
//                   NHS payments, you’ll save time and resources, enabling you to
//                   focus on other tasks and improve efficiency, offering a strong
//                   return from our service.
//                 </div>
//               </div>
//             </div>

//             {/* 3 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseThree"
//                   aria-expanded="false"
//                   aria-controls="collapseThree"
//                 >
//                   How can I trust you with my patients and their data?
//                 </button>
//               </h2>
//               <div
//                 id="collapseThree"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   Our pharmacists are highly trained and adhere to strict GDPR
//                   guidelines, ensuring data protection. We can also provide
//                   references from pharmacies that have experience with our
//                   service.
//                 </div>
//               </div>
//             </div>

//             {/* 4 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseFour"
//                   aria-expanded="false"
//                   aria-controls="collapseFour"
//                 >
//                   What if you don't meet the target number of NMS claims?
//                 </button>
//               </h2>
//               <div
//                 id="collapseFour"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   We work closely with you to meet targets and adjust the
//                   service if needed, ensuring we maximise your revenue.
//                 </div>
//               </div>
//             </div>

//             {/* 5 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseFive"
//                   aria-expanded="false"
//                   aria-controls="collapseFive"
//                 >
//                   How do I get started?
//                 </button>
//               </h2>
//               <div
//                 id="collapseFive"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   Simply review and sign the contract and SLA. Our team will
//                   guide you through the quick and seamless onboarding process,
//                   typically completed in under 10 minutes.
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             {/* 6 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseSix"
//                   aria-expanded="false"
//                   aria-controls="collapseSix"
//                 >
//                   Is there a long-term contract?
//                 </button>
//               </h2>
//               <div
//                 id="collapseSix"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   No, we offer rolling contracts with no long-term commitment,
//                   allowing you to try our service with no obligation.
//                 </div>
//               </div>
//             </div>

//             {/* 7 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseSeven"
//                   aria-expanded="false"
//                   aria-controls="collapseSeven"
//                 >
//                   How long until I see results?
//                 </button>
//               </h2>
//               <div
//                 id="collapseSeven"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   All pharmacies see immediate results within the first month of
//                   implementation.
//                 </div>
//               </div>
//             </div>

//             {/* 8 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseEight"
//                   aria-expanded="false"
//                   aria-controls="collapseEight"
//                 >
//                   What’s included in the service?
//                 </button>
//               </h2>
//               <div
//                 id="collapseEight"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   We manage every aspect of NMS, from patient engagement to
//                   doctor referrals and record documentation, allowing you to
//                   focus on other tasks.
//                 </div>
//               </div>
//             </div>

//             {/* 9 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseNine"
//                   aria-expanded="false"
//                   aria-controls="collapseNine"
//                 >
//                   Can I cancel the service if it’s not working?
//                 </button>
//               </h2>
//               <div
//                 id="collapseNine"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   Yes, you can cancel at any time with no penalty, as we operate
//                   on a rolling contract.
//                 </div>
//               </div>
//             </div>

//             {/* 10 */}
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseTen"
//                   aria-expanded="false"
//                   aria-controls="collapseTen"
//                 >
//                   Can I speak to someone at my convenience?
//                 </button>
//               </h2>
//               <div
//                 id="collapseTen"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   We’re available Monday to Saturday until 9 PM. You can call us
//                   or leave your details below and a member of the team will
//                   contact you.
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
