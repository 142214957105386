import React from "react";
import Img1 from "../assets/computer.jpg"; // Adjust the path as necessary
import Img2 from "../assets/consult.png"; // Adjust the path as necessary
import Img3 from "../assets/lock.png"; // Adjust the path as necessary
import Img4 from "../assets/reports.png"; // Adjust the path as necessary

export const About = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="subSection container">
      <h2 className="w-100 text-center">About Us</h2>
      <p className="my-5">
        At Pharma Services, we understand the ongoing financial challenges and
        increased workloads faced by community pharmacies and are here to
        support you through our comprehensive remote New Medicine Service (NMS)
        management. By leveraging our expertise, we aim to help you maximise
        your monthly revenue from this service.
      </p>
      <h4 className="w-100 text-center my-4">Our service includes:</h4>
      <div class="row row-cols-1 row-cols-md-4 g-4">
        <div class="col">
          <div class="card h-100  primaryShadow">
            <img src={Img1} class="card-img-top cardImg" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Full Management:</h5>
              <p class="card-text">
                We handle all aspects of the NMS, seamlessly integrating with
                all major PMR systems.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card primaryShadow h-100 ">
            <img src={Img2} class="card-img-top cardImg" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Remote Consultations</h5>
              <p class="card-text">
                Our experienced team of pharmacists conducts NMS consultations
                remotely, maintaining the highest standards.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 primaryShadow">
            <img src={Img3} class="card-img-top cardImg" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Compliance</h5>
              <p class="card-text">
                We ensure strict adherence to NHS and GDPR guidelines.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 primaryShadow">
            <img src={Img4} class="card-img-top cardImg" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Monthly Reporting:</h5>
              <p class="card-text">
                At the end of each month, we provide detailed outcomes for every
                NMS completed, in an accessible format from which your MYS claim
                can be submitted.
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => handleScroll("contactUs")}
        className="bg-transparent border-0 w-100 my-5 text-center fs-5 primaryColor"
      >
        Begin Your Journey
        <svg
          style={{ marginLeft: "0.5rem", width: "0.8rem", fill: "#0f8b54" }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
        </svg>
      </button>
    </div>
  );
};
