import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/pharmaServicesLogo.png";

export const MyNavbar = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center bg-dark text-white text-center py-1 fixed-top">
        <svg
          style={{
            marginRight: "0.5rem",
            width: "1rem",
            fill: "white",
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
        </svg>
        info@pharma-services.co.uk
      </div>

      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        fixed="top"
        style={{ top: "2rem" }}
      >
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="Logo" className="logoImg" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link className="mx-3" onClick={() => handleScroll("home")}>
                Home
              </Nav.Link>
              <Nav.Link
                className="mx-3"
                onClick={() => handleScroll("aboutUs")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className="mx-3"
                onClick={() => handleScroll("nmsCalculator")}
              >
                NMS Calculator
              </Nav.Link>
              <Nav.Link className="mx-3" onClick={() => handleScroll("faqs")}>
                FAQs
              </Nav.Link>
              <Nav.Link
                className="mx-3"
                onClick={() => handleScroll("comingSoon")}
              >
                Coming Soon
              </Nav.Link>
              <Nav.Link
                className="mx-3"
                onClick={() => handleScroll("contactUs")}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
