import React from "react";

export const ComingSoon = () => {
  return (
    <div className="subSection container">
      <h2 className="w-100 text-center mb-4">Coming Soon</h2>
      <div className="row">
        {/* Card 1 */}
        <div className="col-md-6 mb-4">
          <div className="card h-100 primaryBackground border-0">
            <div className="card-body">
              <h5 className="card-title text-white fw-bold">DMS</h5>
              <p className="card-text text-white">
                Discharge Medicine Service will streamline patient transitions
                from hospital to community pharmacy, improving continuity of
                care.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-6 mb-4">
          <div className="card h-100 primaryBackground border-0">
            <div className="card-body">
              <h5 className="card-title text-white fw-bold">Pharmacy First</h5>
              <p className="card-text text-white">
                Pharmacy First services will empower pharmacies to offer patient
                consultations, minor ailment treatment, and preventative care
                directly within the community setting.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
